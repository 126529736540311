.board {
    padding: 8px;
}

.title {
    font-family: cursive;
    font-size: 3rem;
    margin: 8px 0;
    text-align: center;
}
